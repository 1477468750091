import React from 'react';
import { Application } from '~/pages/ApplicationDetails/data/useApplication';

type Action =
  | 'decline'
  | 'issuePreOffer'
  | 'passScreening'
  | 'additionalDocumentRequest'
  | 'deferralRequest'
  | 'deferralRequestPreOffer';

type State = {
  application: Application;
  startProcess: (action: Action) => void;
  completeProcess: (action?: Action) => void;
  activeProcess: Action | undefined;
};
const context = React.createContext<State | undefined>(undefined);

type AppProcessingProviderProps = {
  application: Application;
  children: React.ReactNode;
};
export function AppProcessingProvider(props: AppProcessingProviderProps) {
  const [activeProcess, setActiveProcess] = React.useState<Action | undefined>(undefined);

  const value = React.useMemo<State>(() => {
    return {
      application: props.application,
      activeProcess,
      startProcess: (action: Action) => setActiveProcess(action),
      completeProcess: (action?: Action) => {
        setActiveProcess(action);
      },
    };
  }, [activeProcess, props.application]);

  return <context.Provider value={value}>{props.children}</context.Provider>;
}
export function useAppProcessing() {
  const state = React.useContext(context);
  if (!state) throw new Error('useAppProcessing should be used within <useAppProcessingProvider />');
  return state;
}
